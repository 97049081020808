window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});

const recaptchaId = crypto.randomUUID ? crypto.randomUUID() : uuidv4();

window.addEventListener('heyflow-init', (event) => {
    const fieldName = 'recaptchaId';
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = fieldName;
    input.id = fieldName;
    input.setAttribute('data-label', 'reCaptcha Id');
    input.value = recaptchaId;
    input.style = "display:none;"
    
    const form = document.getElementsByTagName('form')[0];
    form.appendChild(input);
});

window.addEventListener('heyflow-submit', (event) => {
    grecaptcha.enterprise.ready(async () => {
        const token = await grecaptcha.enterprise.execute('6Lf4WxEqAAAAAPb5wpxUPd_v-MZgnYZfPrDdzuA9', {action: 'SUBMIT'});
        
        const payload = { recaptchaId, token };
        const url = 'https://hooks.zapier.com/hooks/catch/17378087/22949cf/';
        await fetch(url, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(payload),
            mode: 'no-cors',
        });
    });
});

// https://stackoverflow.com/a/2117523
function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
  );
}